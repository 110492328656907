import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.1.0_@types+node@22.7.7_eslint@9.13.0_rollup@4.24.0_typescript@5._yrtofzu7gqyobsgzi32fhgk54m/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.1.0_@types+node@22.7.7_eslint@9.13.0_rollup@4.24.0_typescript@5._yrtofzu7gqyobsgzi32fhgk54m/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/build/repo/middleware/auth.ts"),
  "auth-without-bind": () => import("/opt/build/repo/middleware/authWithoutBind.ts"),
  public: () => import("/opt/build/repo/middleware/public.ts")
}