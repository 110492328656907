import revive_payload_client_nc7mzbHyAe from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.1.0_@types+node@22.7.7_eslint@9.13.0_rollup@4.24.0_typescript@5._yrtofzu7gqyobsgzi32fhgk54m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RVhX3tJE98 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.1.0_@types+node@22.7.7_eslint@9.13.0_rollup@4.24.0_typescript@5._yrtofzu7gqyobsgzi32fhgk54m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Kkykzk3sXP from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.1.0_@types+node@22.7.7_eslint@9.13.0_rollup@4.24.0_typescript@5._yrtofzu7gqyobsgzi32fhgk54m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kIabvWGA0B from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.1.0_@types+node@22.7.7_eslint@9.13.0_rollup@4.24.0_typescript@5._yrtofzu7gqyobsgzi32fhgk54m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_uuvDqPJAlA from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.1.0_@types+node@22.7.7_eslint@9.13.0_rollup@4.24.0_typescript@5._yrtofzu7gqyobsgzi32fhgk54m/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_uMZ0piZzfD from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.1.0_@types+node@22.7.7_eslint@9.13.0_rollup@4.24.0_typescript@5._yrtofzu7gqyobsgzi32fhgk54m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bIGsqaACAL from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.1.0_@types+node@22.7.7_eslint@9.13.0_rollup@4.24.0_typescript@5._yrtofzu7gqyobsgzi32fhgk54m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_ncPwaWYC9N from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.1.0_@types+node@22.7.7_eslint@9.13.0_rollup@4.24.0_typescript@5._yrtofzu7gqyobsgzi32fhgk54m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_XvcoioNgZE from "/opt/build/repo/node_modules/.pnpm/dayjs-nuxt@2.1.11_rollup@4.24.0/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import sentry_client_fWHSW13Gt0 from "/opt/build/repo/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_6mni4fs2miheq54sfjxwm4ls6q/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/build/repo/.nuxt/sentry-client-config.mjs";
import plugin_n9lNd8fk6K from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.5.6_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/build/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_Y7D5iE0hRz from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_rollup@4.24.0_vite@5.4.9_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import clerk_XbpSWsrCmw from "/opt/build/repo/plugins/clerk.ts";
export default [
  revive_payload_client_nc7mzbHyAe,
  unhead_RVhX3tJE98,
  router_Kkykzk3sXP,
  payload_client_kIabvWGA0B,
  navigation_repaint_client_uuvDqPJAlA,
  check_outdated_build_client_uMZ0piZzfD,
  chunk_reload_client_bIGsqaACAL,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ncPwaWYC9N,
  plugin_XvcoioNgZE,
  sentry_client_fWHSW13Gt0,
  sentry_client_config_o34nk2sJbg,
  plugin_n9lNd8fk6K,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_Y7D5iE0hRz,
  clerk_XbpSWsrCmw
]