import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as bindtqTOspwneYMeta } from "/opt/build/repo/pages/account/bind.vue?macro=true";
import { default as _91id_93PuQcGenwO1Meta } from "/opt/build/repo/pages/cas/clubs/[id].vue?macro=true";
import { default as index2clhwWrcULMeta } from "/opt/build/repo/pages/cas/clubs/index.vue?macro=true";
import { default as ratingBbwpr1nRByMeta } from "/opt/build/repo/pages/cas/rating.vue?macro=true";
import { default as _91id_93tpbWk0JC8EMeta } from "/opt/build/repo/pages/forms/[id].vue?macro=true";
import { default as indexrYVduI2qbjMeta } from "/opt/build/repo/pages/forms/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as managevKDzS33pJ5Meta } from "/opt/build/repo/pages/manage/manage.vue?macro=true";
import { default as recordk2u1Akab3qMeta } from "/opt/build/repo/pages/manage/record.vue?macro=true";
import { default as reservationefGe1agjjMMeta } from "/opt/build/repo/pages/manage/reservation.vue?macro=true";
import { default as statusesvC1ixlvQV9Meta } from "/opt/build/repo/pages/manage/statuses.vue?macro=true";
import { default as profileNJNbyWXx9rMeta } from "/opt/build/repo/pages/profile.vue?macro=true";
import { default as sign_45in5udJkCQKbYMeta } from "/opt/build/repo/pages/sign-in.vue?macro=true";
import { default as sign_45up3Rqnj2isW5Meta } from "/opt/build/repo/pages/sign-up.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutlv6i1FL0CfMeta || {},
    component: () => import("/opt/build/repo/pages/about.vue")
  },
  {
    name: "account-bind",
    path: "/account/bind",
    meta: bindtqTOspwneYMeta || {},
    component: () => import("/opt/build/repo/pages/account/bind.vue")
  },
  {
    name: "cas-clubs-id",
    path: "/cas/clubs/:id()",
    meta: _91id_93PuQcGenwO1Meta || {},
    component: () => import("/opt/build/repo/pages/cas/clubs/[id].vue")
  },
  {
    name: "cas-clubs",
    path: "/cas/clubs",
    meta: index2clhwWrcULMeta || {},
    component: () => import("/opt/build/repo/pages/cas/clubs/index.vue")
  },
  {
    name: "cas-rating",
    path: "/cas/rating",
    meta: ratingBbwpr1nRByMeta || {},
    component: () => import("/opt/build/repo/pages/cas/rating.vue")
  },
  {
    name: "forms-id",
    path: "/forms/:id()",
    meta: _91id_93tpbWk0JC8EMeta || {},
    component: () => import("/opt/build/repo/pages/forms/[id].vue")
  },
  {
    name: "forms",
    path: "/forms",
    meta: indexrYVduI2qbjMeta || {},
    component: () => import("/opt/build/repo/pages/forms/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "manage-manage",
    path: "/manage/manage",
    meta: managevKDzS33pJ5Meta || {},
    component: () => import("/opt/build/repo/pages/manage/manage.vue")
  },
  {
    name: "manage-record",
    path: "/manage/record",
    meta: recordk2u1Akab3qMeta || {},
    component: () => import("/opt/build/repo/pages/manage/record.vue")
  },
  {
    name: "manage-reservation",
    path: "/manage/reservation",
    meta: reservationefGe1agjjMMeta || {},
    component: () => import("/opt/build/repo/pages/manage/reservation.vue")
  },
  {
    name: "manage-statuses",
    path: "/manage/statuses",
    meta: statusesvC1ixlvQV9Meta || {},
    component: () => import("/opt/build/repo/pages/manage/statuses.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45in5udJkCQKbYMeta || {},
    component: () => import("/opt/build/repo/pages/sign-in.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45up3Rqnj2isW5Meta || {},
    component: () => import("/opt/build/repo/pages/sign-up.vue")
  }
]